@font-face {
    font-family: PPNeueMontreal;
    font-style: normal;
    src: url("../public/fonts/PPNeueMontreal.otf") format("opentype");
}

@font-face {
    font-family: PPNeueMontrealSemiBold;
    font-style: normal;
    src: url("../public/fonts/PPNeueMontreal-SemiBold.otf") format("opentype");
}

#root {
    height: 100vh;
}

html, body {
    background: white;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    color: black;
    font-weight: 400;
    margin: 0;
    padding: 0;
    position: relative;
    font-size: 12px;
    max-width: 1200px;
    min-width: 320px;
    margin: 0 auto;
    line-height: 1.4;
}

html * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html *:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html *:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: inherit;
}

a:focus, a:visited {
    outline: none;
    text-decoration: none;
    color: inherit;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

button {
    padding: 0;
    margin: 0;
    cursor: pointer;
    background: none;
    outline: none;
    border: none;
    color: inherit;
}

button:focus, button:visited {
    outline: none;
    text-decoration: none;
    color: inherit;
}

p {
    margin: 0;
    padding: 0;
}

section {
    width: 100%;
    height: 100%;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

section > article {
    width: 100%;
    padding-top: 40px;
}

section .generator {
    font-family: PPNeueMontreal, sans-serif;
    letter-spacing: 0.1em;
    line-height: 17px;
    font-size: 13px;
    background: #f5f5f5;
    padding-left: 50px;
}

section .generator header {
    font-size: 16px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-align: center;
    align-items: center;
    text-transform: uppercase;
    margin-bottom: 62px;
}

section .title {
    font-size: 22px;
    line-height: 26px;
    align-self: baseline;
    margin: 0 0 0 15px;
}

section .generator header img {
    width: 100%;
    max-width: 165px;
}

section .generator header > div span {
    font-family: PPNeueMontrealSemiBold, sans-serif;
}

section .generator header > div:first-of-type {
    margin-right: 35px;
}

section .generator label, section .generator .label {
    text-transform: uppercase;
    display: block;
    margin-bottom: 9px;
}

section .generator .city {
    font-size: 14px;
    margin-bottom: 20px;
}

section .generator label > span, section .generator .label > span {
    text-transform: none;
}

section .generator .office-choice {
    margin-bottom: 60px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

section .generator .office-choice span {
    text-transform: uppercase;
    display: block;
}

section .generator .office-choice .single-option {
    width: 100%;
    color: #848484;
}

section .generator input[type="text"],
section .generator input[type="tel"] {
    background: transparent;
    border: none;
    border-bottom: 1px solid black;
    outline: none;
    padding-bottom: 10px;
    max-width: 318px;
    width: 80%;
    margin-bottom: 20px;
}

section .generator [type="radio"]:checked,
section .generator [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

section .generator [type="radio"]:checked + label,
section .generator [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #848484;
}

section .generator [type="radio"]:checked + label:before,
section .generator [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    width: 14px;
    height: 14px;
    border: 1px solid black;
    border-radius: 100%;
}

section .generator [type="radio"]:checked + label:after,
section .generator [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 14px;
    height: 14px;
    background: black;
    border-radius: 100%;
    position: absolute;
    top: 3px;
    left: 0;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

section .generator [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
}

section .generator [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

section .generator .checkbox-wrap {
    width: fit-content;
    cursor: pointer;
    display: block;
    position: relative;
    padding-left: 25px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 10px;
}

section .generator .checkbox-wrap label {
    cursor: pointer;
}

section .generator .checkbox-wrap input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

section .generator .checkbox-wrap .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    width: 13px;
    height: 13px;
    border: 1px solid black;
}

section .generator .checkbox-wrap .checkmark::after {
    content: "";
    position: absolute;
    display: none;
    left: 50%;
    top: 47%;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

section .generator .checkbox-wrap input:checked ~ .checkmark {
    background-color: black;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

section .generator .checkbox-wrap input:checked ~ .checkmark:after {
    display: block;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

section .result {
    padding-left: 130px;
    padding-top: 135px;
}

section .result header {
    font-family: PPNeueMontreal, sans-serif;
    font-size: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-bottom: 20px;
}

section .result header span {
    font-family: PPNeueMontrealSemiBold, sans-serif;
}

section .result .footer-wrapper img {
    width: 100%;
    max-width: 478px;
}

section .result .footer-wrapper .separator {
    height: 1px;
    background: black;
    width: 100%;
}

section .result .footer-wrapper .separator.top {
    max-width: 420px;
    margin-bottom: 45px;
}

section .result .footer-wrapper .separator.bottom {
    margin-top: 45px;
}

.login-form {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 318px;
    width: 80%;
}

.login-form label {
    margin-bottom: 9px;
    text-transform: uppercase;
}

.login-form input, .login-form label {
    display: block;
}

.login-form input {
    background: transparent;
    border: none;
    border-bottom: 1px solid black;
    outline: none;
    padding-bottom: 10px;
    width: 100%;
    margin-bottom: 20px;
}

.login-form input.error-input {
    border-bottom: 1px solid red;
    margin-bottom: 5px;
}

.login-form button {
    background: black;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    color: white;
    padding: 10px 50px;
    border: 1px solid transparent;
}

.login-form button:hover {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background: white;
    color: black;
    border: 1px solid black;
}

section .result .footer-content {
    overflow-y: auto;
    margin-bottom: 35px;
}

.copy-button {
    display: inline-flex;
    align-items: center;
    font-family: PPNeueMontreal, sans-serif;
    font-size: 14px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    width: 50%;
    height: 46px;
    border: 1px solid black;
    color: black;
    transition-duration: 0.3s;
}

.copy-icon {
    position: relative;
    right: 10px;
    top: 0;
}

.copy-button .copy-icon-path {
    transition-duration: 0.3s;
    fill: black;
}

.copy-button:hover {
    background-color: black;
    color: white;
    transition-duration: 0.3s;
}

.copy-button:hover .copy-icon-path {
    transition-duration: 0.3s;
    fill: white;
}

.login-form > .error {
    color: red;
    margin-bottom: 20px;
    text-transform: uppercase;
}

@media (min-width: 570px) {
    section > article:first-of-type {
        width: 40%;
    }
    section > article:nth-of-type(2) {
        width: 60%;
    }
    section .generator header > div:first-of-type {
        margin-right: 35px;
    }
}

@media (max-width: 570px) {
    section .result {
        padding-left: 50px;
    }
}

@media (min-width: 820px) {
    section .generator header > div:first-of-type {
        margin-right: 35px;
    }
    section .generator .office-choice .single-option {
        width: 50%;
    }
}

@media (min-width: 1200px) {
    html, body {
        margin: 0;
    }
}

.admin-textarea {
    width: 80%;
}

.admin-input {
    margin-bottom: 10px;
}

.button-admin {
    width: 50%;
    margin: 0 5px 0 5px;
}
